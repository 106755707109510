
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonBadge } from '@ionic/vue';
import { bagHandle, cart, personCircle } from 'ionicons/icons';
import { defineComponent } from "vue";
import cartModule from '@/store/modules/cart';

export default defineComponent({
  name: 'Tabs',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonBadge},
  props: {},
  setup() {
    return {
      cartModule,
      bagHandle,
      cart,
      personCircle
    }
  }
})

<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="carta" href="/carta">
          <ion-icon :icon="bagHandle"/>
          <ion-label>Carta</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="carrito" href="/carrito">
          <ion-badge v-if="cartModule.cartNumItems" color="accent">{{ cartModule.cartNumItems }}</ion-badge>
          <ion-icon :icon="cart"/>
          <ion-label>Carrito</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="cuenta" href="/club">
          <ion-icon :icon="personCircle"/>
          <ion-label>Mi cuenta</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonBadge } from '@ionic/vue';
import { bagHandle, cart, personCircle } from 'ionicons/icons';
import { defineComponent } from "vue";
import cartModule from '@/store/modules/cart';

export default defineComponent({
  name: 'Tabs',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonBadge},
  props: {},
  setup() {
    return {
      cartModule,
      bagHandle,
      cart,
      personCircle
    }
  }
})
</script>
